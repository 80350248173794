/**
 * Emergency Message Page
 * 
 * Main page for the emergency message. Shows a madlib-style fill in the blanks
 * section, where the team needs to use the question answers, and a cipher to find out
 * what goes in each blank.
 * 
 * NOTE: The emergency message is currently hard coded. Eventually the emergency message
 * text should be added in to the admin portion, so that the watson team can
 * update the emergency message text themselves. It's a normal string, with brackets around
 * numbers that reference the question number that fills in that blank. Each bracketed number
 * gets transformed into a blank input that the user can fill in.
 */

import React, { useContext, useState, useEffect } from 'react'
import AppContext from '../../utils/context'

import Modal from 'react-bootstrap/Modal'
import CountUp from 'react-countup'

import LoggedInWrapper from '../../components/loggedinWrapper'
import SvgArrow from '../../components/svg/arrow'
import SvgCheck from '../../components/svg/check'
import Link from '../../components/link'
import { getCurrentHuntData } from '../../utils/hunt-data'
import { b64DecodeUnicode, getStaticUrl } from '../../utils/helpers'
import { DirigibleIllustration, CrownIllustration, KeysIllustration } from '../../components/images/illustrations'
import { getCurrentTeamData, isTeamCaptain, teamAnswerEmergencyMessage } from '../../utils/team-data'
import MadLib from '../../components/emergency-message/madlib'
import FloatingInput from '../../components/floating-input'
import { setCurrentEmergencyMessageFinalAnswer, getCurrentEmergencyMessageData, setCurrentEmergencyMessageData } from '../../utils/emergency-message-data'
import { getSiteData } from '../../utils/site'

const message = `If I am dead, get this message to Agent John Parker at the CIA. I was able to [1] the [2] [3]. They know that I am working for the [4], posing as a Watson Adventures staffer, a job that lets me [1] major companies. I have led senior [2] [5] to believe that I am [6] with the [7] and will pass them information about [8] [5] on a [9]. It also contains a [10] designed to cripple the [2] [11]. If I am dead or missing, the [2] [5] have probably discovered the [10] and will use it against the [7]. I have hidden a [12] within the scavenger hunt. Contact [13] at [14] to uncover the [12] and [15] it to my “[16]” ASAP. If you [15] the correct [12], you will receive a [15] with these three words:`

const EmergencyMessagePage = () => {
  const context = useContext(AppContext)
  const [data, setData] = useState({})
  const [teamData, setTeamData] = useState({})
  const [globalSettings, setGlobalSettings] = useState(getSiteData())
  const [final_answer, setFinalAnswer] = useState()
  const [possible_points, setPossiblePoints] = useState(15)
  const [points, setPoints] = useState(0)
  const [modalShow, setModalShow] = useState(false)
  const [incorrectModalShow, setIncorrectModalShow] = useState(false)
  const [play_sounds, setPlaySounds] = useState(true)
  const [success_audio, setSuccessAudio] = useState()
  const [soft_fail_audio, setSoftFailAudio] = useState()

  let success_text = `Good job smarty pants!`
  if (
    data.hunt !== undefined &&
    data.hunt.settings !== null &&
    data.hunt.settings.success_text !== null
  ) {
    success_text = data.hunt.settings.success_text
  } else if (globalSettings !== undefined && globalSettings.success_text !== undefined) {
    success_text = globalSettings.success_text
  }

  let is_complete = points == possible_points

  useEffect(() => {
    const data = getCurrentHuntData()
    setData(data)
    setTeamData(getCurrentTeamData())
    let emData = getCurrentEmergencyMessageData()
    setFinalAnswer(emData.final_answer)
    setPossiblePoints(data.emergency_message && data.emergency_message.point_value ? data.emergency_message.point_value : 15)

    let success_sound = data.hunt && data.hunt.sound_success ? data.hunt.sound_success.file : getStaticUrl(`/sounds/ding.mp3`)
    let soft_fail_sound = data.hunt && data.hunt.sound_soft_fail ? data.hunt.sound_soft_fail.file : getStaticUrl(`/sounds/duck.mp3`)
    setPlaySounds(data.hunt && data.hunt.play_sounds == 1)

    setSuccessAudio(new Audio(success_sound))
    setSoftFailAudio(new Audio(soft_fail_sound))

    context.setLoading(false)

    // set up interval to pay attention to team answers updates
    const interval = setInterval(() => {

      setTeamData(getCurrentTeamData())
    }, 5000)
    // clear the interval on unmount
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    setCurrentEmergencyMessageFinalAnswer(final_answer)
  }, [final_answer])

  useEffect(() => {
    setPoints(teamData.emergency_message_answers && teamData.emergency_message_answers.points ? teamData.emergency_message_answers.points : 0)
  }, [teamData, teamData.emergency_message_answers])

  const playSuccess = () => {
    if (play_sounds) {
      success_audio.play()
    }
  }

  const playSoftFail = () => {
    if (play_sounds) {
      soft_fail_audio.play()
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (isTeamCaptain()) {
      // check if final answer is correct
      // decode and split the accepted answers
      let answers = b64DecodeUnicode(data.emergency_message.answer_aes_crypt).toLowerCase().split(`,`)
      let em_data = getCurrentEmergencyMessageData()
      
      // check if there's anything input at all
      if(final_answer.length){
        var f_answer = final_answer.toLowerCase()
        let found = false
        for(const a of answers){
          console.log(a)
          if(f_answer == a){
            // it's correct
            found = true
            em_data[`points`] = possible_points
            playSuccess()
            setCurrentEmergencyMessageData(em_data)
            setPoints(possible_points)
            teamAnswerEmergencyMessage(em_data)
            setModalShow(true)
            break
          }
        }
        // incorrect
        if(!found){
          playSoftFail()
          setIncorrectModalShow(true)
        }
      }
    }
  }


  return (
    <LoggedInWrapper>
      {data.emergency_message !== undefined && (
        <>
          <div className="site-sub-header px-4 py-2">
            <div className="row">
              <div className="col-2 d-flex align-items-center">
                <Link to={`/`} className={`back-arrow`}>
                  <SvgArrow />
                </Link>
              </div>
              <div className="col-10 text-right">
                <h1 className={`title h5 smaller text-uppercase mb-0 d-inline`}>
                  {data.emergency_message.display_title
                    ? data.emergency_message.display_title
                    : `Emergency Message`}
                </h1>
                <span className="points title h5 smaller ml-2">
                      ({points}/{possible_points})
                </span>
                {is_complete && (
                  <span
                    className={`check-circle smaller correct ml-2`}
                    style={{
                      position: `relative`,
                      top: `-3px`,
                    }}>
                    <span className={`d-flex align-items-center justify-content-center w-100 h-100`}>
                      <SvgCheck />
                    </span>
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="mt-3 mb-3">
            
            <div className={`card mx-4`}>
              <div className="card-header text-center">
                <h3>
                  {data.emergency_message.display_title
                    ? data.emergency_message.display_title
                    : `Emergency Message`}
                </h3>
              </div>
              <div className="card-body pb-3">
                <div className="card-text" dangerouslySetInnerHTML={{ __html: data.emergency_message.description }} />
                <MadLib message={message} />
                {isTeamCaptain() && !is_complete ?
                  <form
                    className={`question-form mt-auto pb-3`}
                    method="post"
                    onSubmit={handleSubmit}>
                    <FloatingInput 
                      name={`answer-emergency-message`}
                      placeholder={data.emergency_message.input_help_text
                        ? data.emergency_message.input_help_text
                        : ``}
                      onChange={(event) => setFinalAnswer(event.target.value)}
                      value={final_answer}
                    />
                    <input className={`btn btn-block btn-danger`} type="submit" value="Submit Answer!" />
                  </form>
                  :
                  <FloatingInput 
                    name={`answer-emergency-message`}
                    placeholder={``}
                    onChange={(event) => setFinalAnswer(event.target.value)}
                    value={final_answer}
                  />
                }

              </div>
            </div>
            <div className="px-4 my-3">
              <div className="row">
                <div className="col-md-6 pb-3">
                  <Link to={`/answers/`} className="btn btn-block btn-primary">{data.hunt.answer_sheet_title
                    ? data.hunt.answer_sheet_title
                    : `Team Answer Sheet`}</Link>
                </div>
                {/* <div className="col-md-6 pb-3">
                  <Link to={`/emergency-message/cipher/`} className="btn btn-block btn-primary">Cipher</Link>
                </div> */}
              </div>
            </div>
          </div>
          <Modal
            show={incorrectModalShow}
            onHide={() => setIncorrectModalShow(false)}
            centered
            dialogClassName={`defaultDialog`}
            backdropClassName={`app-overlay`}>
            <Modal.Body>
              <div className={`card question-modal-card`}>
                <div className={`card-header text-center`}>
                  <h3>{data.emergency_message.display_title
                    ? data.emergency_message.display_title
                    : `Emergency Message`}</h3>
                </div>
                <div className={`card-body px-3 py-4 text-center`}>
                  <div className="incorrect-answer py-5 px-5 text-center">
                    <KeysIllustration />
                    <p className="mt-3">Sorry, that&apos;s not the correct answer!</p>
                  </div>
                </div>
                <div className={`card-footer py-3 no-background`}>
                  <button className={`btn btn-success btn-block`} onClick={() => setIncorrectModalShow(false)}>
                Try Again
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            centered
            dialogClassName={`defaultDialog`}
            backdropClassName={`app-overlay`}>
            <Modal.Body>
              <div className={`card question-modal-card success`}>
                <div className={`card-header text-center`}>
                  <div className={`row align-items-center justify-content-between`}>
                    <div className={`col-8`}>
                      <h3 className={`mb-0`}>{data.emergency_message.display_title
                        ? data.emergency_message.display_title
                        : `Emergency Message`}</h3>
                    </div>
                    <div className={`col-4 text-right`}>
                      <span className={`check-circle`}>
                        <span className={`d-flex align-items-center justify-content-center w-100 h-100`}>
                          <SvgCheck />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className={`card-body`}>
                  <div className="question-end py-3">
                    <div className="text-center">
                      <p className={`answer`}>You Did It!</p>
                      <div className="row no-gutters align-items-center justify-content-center mb-3 points-container">
                        <div className="col text-right">
                      You
                          <br />
                      earned
                        </div>
                        <div className="col-6 points px-2">
                          <CountUp end={points} delay={1} /> / {possible_points}
                        </div>
                        <div className="col text-left">
                      possible
                          <br />
                      points!
                        </div>
                      </div>
                      <p className={`end-text`}>{success_text}</p>
                      <div className="d-flex justify-content-center">
                        <div className={`icon-container`}>
                          <CrownIllustration />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`card-footer py-3 `}>
                  <button className={`btn btn-danger btn-block btn-arrow`} onClick={() => setModalShow(false)}>
                Continue
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </LoggedInWrapper>
  )
}

export default EmergencyMessagePage