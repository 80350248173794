/**
 * Madlib Style Component
 * 
 * Displays a madlib style text and input combination. Handles the input of
 * text into each blank, and automatically fills in other blanks that share the
 * same number. 
 * 
 * Used in the Emergency Message Page.
 */

import React, {useContext, useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import AppContext from '../../utils/context'

import { getCurrentHuntData, getHuntQuestionDisplay } from '../../utils/hunt-data'
import { getCurrentTeamData } from '../../utils/team-data'
import FloatingInput from '../floating-input'
import { getCurrentEmergencyMessageData, setCurrentEmergencyMessageAnswers } from '../../utils/emergency-message-data'

const MadLib = ({message}) => {
  
  const context = useContext(AppContext)
  const [data, setData] = useState({})
  const [teamData, setTeamData] = useState({})

  const [answers, setAnswers] = useState({})

  useEffect(() => {
    setData(getCurrentHuntData())
    setTeamData(getCurrentTeamData())
    let emData = getCurrentEmergencyMessageData()
    setAnswers(emData.answers ? emData.answers : {})
    context.setLoading(false)

    // set up interval to pay attention to team answers updates
    const interval = setInterval(() => {
      setTeamData(getCurrentTeamData())
    }, 5000)
    // clear the interval on unmount
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    setCurrentEmergencyMessageAnswers(answers)
  }, [answers])

  const handleAnswersUpdate = (value, number) => {
    setAnswers({ ...answers, [number]: value })
  }

  const teamQuestionNumber = (real_num) => {
    // figure out the question number based on the team's start point and direction
    let team_start_idx = teamData.start - 1
    let team_dir = teamData.direction
    let team_q_num = real_num
    if(team_dir == `clockwise`){
      // team is going forwards
      team_q_num = real_num - team_start_idx
      if(team_q_num < 1){
        team_q_num = data.questions.length + team_q_num
      }

    } else if(team_dir == `counterclockwise`){
      // team is going backwards
      if(team_q_num > teamData.start){
        team_q_num = -team_q_num + (data.questions.length + teamData.start + 1)
      } else {
        team_q_num = -team_q_num + (teamData.start + 1)
      }
    }
    return team_q_num
  }

  return (
    <div className="madlib-container">
      {message.split(`[`).map((part, idx) => {
        // separate into number and the rest of the text
        var regex = /(^\d+)?]?(.*)$/
        var match = part.match(regex)
        // console.log(match)
        var number_match = match[1]
        var number = parseInt(number_match)
        var number_str = teamQuestionNumber(number)
        var text = match[2]
        
        return <span key={`part-${idx}`}>
          {number_match && 
            <FloatingInput 
              name={`message-input-${idx}`} 
              placeholder={number_str}
              value={answers[number] ? answers[number] : ``}
              className={`inline-input`}
              onChange={(event) => handleAnswersUpdate(event.target.value, number)}
            />
          }
          {text && <span>{text}</span>}
        </span>
      })}
    </div>
  )
}

MadLib.propTypes = {
  message: PropTypes.string,
  space_answers: PropTypes.object,
}

MadLib.defaultProps = {
  message: ``,
  space_answers: {},
}

export default MadLib