import API from './api'

const isBrowser = typeof window !== `undefined`

const getEmergencyMessageData = () => (window.localStorage.emData ? JSON.parse(window.localStorage.emData) : {})

export const setEmergencyMessageData = data => (window.localStorage.emData = JSON.stringify(data))

export const getCurrentEmergencyMessageData = () => isBrowser && getEmergencyMessageData()

export const setCurrentEmergencyMessageData = data => isBrowser && setEmergencyMessageData(data)

export const setCurrentEmergencyMessageAnswers = (answers) => {
  if (!isBrowser) return false
  let emData = getEmergencyMessageData()
  emData.answers = answers
  setEmergencyMessageData(emData)
  return null
}

export const setCurrentEmergencyMessageFinalAnswer = (answer) => {
  if (!isBrowser) return false
  let emData = getEmergencyMessageData()
  emData.final_answer = answer
  setEmergencyMessageData(emData)
  return null
}
